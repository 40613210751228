import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <section className="header">
        <div className="container sm-disable-container">
          <nav className="navbar navbar-expand-lg" style={{ gap: '20px' }}>
            <a className="navbar-brand" href="https://tamracapital.sa/en">
              <img src="logo.jpeg" className="main-logo" alt="logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <svg
                id="menu-alt"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="icon line"
                width="48"
                height="48"
              >
                <path
                  id="primary"
                  d="M3,18H21M3,12H21M3,6H21"
                  fill="none"
                  stroke="rgb(0, 0, 0)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                ></path>
              </svg>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav left-nav" style={{ width: '100%' }}>
                <li className="nav-item how-to-start" style={{ flexBasis: '60%' }}>
                  <div className="nav-link">How to Start</div>
                </li>
                <li className="nav-item li-icon-user">
                  <a className="nav-link btn-login-1" href="https://app-new.tamracapital.sa/login" id="login-route">
                    Login
                  </a>
                </li>
                <li className="nav-item sm-btn-li">
                  <a className="nav-link btn-login ms-10px" href="https://app-new.tamracapital.sa/register" id="register-route">
                    Get Started
                  </a>
                </li>
                <li className="nav-item toggle-lang-container dark">
                  <a className="nav-link" href="https://tamracapital.sa/">
                    العربية
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </section>

      <section className="home-hero bg-app-banner">
        <div className="container">
          <div className="row-zakat">
            <div className="col-md-6 sm-100w">
              <div className="content content-padding text-center">
                <h1 className="home-hero-title text-center">Invest with Tamra Capital</h1>
                <h1 className="home-hero-title-md text-center mb-3rem">More Accessible... Simpler... Quicker!</h1>
              </div>
              <div className="download-text">
                <div className="text-center">Download the App</div>
                <div className="download-btn-group">
                  <a href="https://apps.apple.com/sa/app/tamra-capital-save-invest/id1672321079" target="_blank" rel="noopener noreferrer" className="download-btn">
                    <img id="apple-store" src="appleStoreBtn.png" className="download-btn" alt="" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=sa.com.tamracapital&amp;hl=ar_SA" target="_blank" rel="noopener noreferrer" className="download-btn">
                    <img id="google-play" src="googlePlayBtn.png" className="download-btn" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img src="2.jpeg" className="app_banner lg-img" alt="" />
              <img src="2.jpeg" className="app_banner md-img" alt="" />
              <img src="2.jpeg" className="app_banner sm-img" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="about pt-150 pb-200" id="about" data-lang="en">
        <div className="container">
          <div className="row vcenter-item">
            <div className="col-md-6">
              <h2 className="about-title" id="about--t" data-register-btn="Get Started">
                About Tamra Capital
              </h2>
              <p className="about-p">
                Tamra Capital is a Saudi investment company licensed by the Capital Market Authority to provide
                financial advisory services on August 26, 2020, and to provide investment management services on
                January 14, 2021. We empower individuals by granting them direct access to local and global
                investment funds in simple and automated steps through a secured platform that removes the burden
                of investing and ensures accuracy. We help you get the most out of your money by investing in
                Shariah-compliant Exchange-Traded Funds (ETFs). Investing is generally limited to a small group of
                individuals who have the know-how and experience. Tamra Capital acquired Capital Market Authority
                approval’s to start operations on November 23, 2021. We launched our investment platform to make
                investing available for everyone, anywhere through a state-of-the-art platform that makes it much
                easier to invest, backed by investment experts with a deep experience in the entire process of
                investing.
              </p>
              <div className="about-s-img">
                <a href="https://shariyah.org/ccp/uploads/certificate_162ee396111d10.pdf" target="_blank" rel="noopener noreferrer">
                  <img src="Stamp.png" className="Stamp" />
                </a>
                <a href="https://cma.org.sa/Market/NEWS/pages/CMA_N_2843.aspx" target="_blank" rel="noopener noreferrer">
                  <img src="Capital-Market-Authority.png" className="Capital" />
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-img-div">
                <div style={{ width: '100%', height: '100%' }}>
                  <img src="6.png" style={{ width: '90%' }} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-arrow-mobile-bottom hide-desktop">
          <img src="about-arrow-mobile.svg" className="about-arrow-mobile" />
        </div>
      </section>

      <section className="about-video pt-100 pb-150 bg-gray text-center" id="about-us">
        <div className="container">
          <div className="main-title">
            <h1 className="about-v-title">Start investing today and take control of your future!</h1>
          </div>

          <div className="row">
            <div className="col-md-12 text-center">
              <div className="video-thumbnail">
                <a href="javascript:;" id="play-video">
                  <img src="icon-play.svg" className="video-play" />
                </a>
                <img src="1.jpeg" className="about-v" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wallet-type pt-100" id="investment-wallet">
        <div className="container">
          <div className="main-title text-center">
            <h4 className="wallet-title-s">Investment Strategies</h4>
            <h1 className="wallet-title" id="investment-wallet--t" data-register-btn="Start Now">
              Invest in a well-diversified and balanced portfolio.
              <img src="about-v-t-icon.png" className="about-v-t-icon" />
            </h1>
            <p className="wallet-para">
              What is an investment portfolio? <br /> It is a collection of various investments across different
              assets, such as stocks, Sukuks, real estate, and commodities, designed to minimize overall risk
              through diversification and enhance long-term returns. We assist you in building a flexible investment
              portfolio through straightforward steps. How?
            </p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="wallet-card text-center">
                <img src="wallet-card-1.svg" className="wallet-card" />
                <div className="card-img-overlay">
                  <h4 className="wallet-card-title">Portfolio</h4>
                  <p className="wallet-card-para">
                    A tailored investment portfolio designed to help you achieve your investment goals, guided by
                    our comprehensive risk-assessment survey.
                  </p>
                  <img src="wallet-icon-1.svg" className="wallet-icon" />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <img src="wallet-coin-img.webp" className="wallet-coin" />
              <div className="wallet-card text-center">
                <img src="wallet-card-2.svg" className="wallet-card" />
                <div className="card-img-overlay">
                  <h4 className="wallet-card-title">Diversified</h4>
                  <p className="wallet-card-para">
                    A diversified investment portfolio that spreads your investments across various financial assets
                    based on your responses in the risk assessment questionnaire, helping to reduce overall risk.
                  </p>
                  <img src="wallet-icon-2.svg" className="wallet-icon" />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="wallet-card text-center">
                <img src="wallet-card-3.svg" className="wallet-card" />
                <div className="card-img-overlay">
                  <h4 className="wallet-card-title">Balanced</h4>
                  <p className="wallet-card-para">
                    A well-balanced portfolio, mixing high and low-risk investments, crafted to align with your
                    financial goals and risk tolerance.
                  </p>
                  <img src="wallet-icon-3.svg" className="wallet-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cta pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="cta-content">
                <h4 className="cta-title">
                  Invest in Tamra Capital’s portfolios to secure your financial future
                </h4>
                <p className="cta-para">
                  Explore a wide range of investment options designed to align with your risk profile and financial
                  goals, including Conservative, Moderately Conservative, Moderately Aggressive, and Aggressive
                  portfolios.
                </p>
                <a href="https://tamracapital.sa/en/how-tamra-works#investment-wallets" className="main-btn btn-blue">
                  Discover More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-invest" id="why-tamra">
        <div className="container">
          <div className="main-title text-center">
            <h1 className="why-invest-main-title" id="why-tamra--t">
              Why should you <span className="t-blue">invest today?</span>
            </h1>
            <img
              className="mb-5"
              src="3.jpeg"
              style={{ maxWidth: '450px', borderRadius: '10px', border: '2px solid #ffc400' }}
              alt="Investment Illustration"
            />
          </div>
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="why-invest-card">
                <img
                  src="why-invest-icon-1.png"
                  className="why-invest-icon"
                  alt="Secure Financial Future Icon"
                />
                <h4 className="why-invest-title">Secure a better financial future</h4>
                <p className="why-invest-para">
                  Long-term investing secures financial independence for your future, whether it's for
                  retirement or to fulfill your personal dreams at any time.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="why-invest-card">
                <img
                  src="why-invest-icon-2.png"
                  className="why-invest-icon"
                  alt="Grow Financial Resources Icon"
                />
                <h4 className="why-invest-title">Grow your financial resources</h4>
                <p className="why-invest-para">
                  Smart investing safeguards your wealth from inflation and helps grow your financial
                  resources over time.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="why-invest-card">
                <img
                  src="why-invest-icon-3.png"
                  className="why-invest-icon"
                  alt="Investing with Technology Icon"
                />
                <h4 className="why-invest-title">Investing simplified with cutting-edge technology</h4>
                <p className="why-invest-para">
                  With advanced technologies, local and global investment opportunities have become simpler
                  and more accessible than ever before.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Invest Section */}
      <section className="invest pt-150 pb-150" id="start">
        <div className="container">
          <div className="row vcenter-item">
            <div className="col-md-6">
              <div className="invest-content">
                <h1 className="invest-title">We manage your investments wisely.</h1>
                <p className="invest-para">
                  Tamra Capital acts as the digital manager of your wealth by strategically investing and
                  balancing your funds in long-term, diversified Exchange-Traded Funds. This approach ensures
                  that your investments are optimized according to your acceptable risk appetite. When one asset
                  experiences a performance drop, it is balanced by gains in other assets, keeping your portfolio
                  stable and secure through a smart, automated financial process.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src="invest_en.webp"
                className="invest-img"
                alt="Invest Wisely Illustration"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Security Section */}
      <section className="security pb-150">
        <div className="container">
          <div className="row vcenter-item">
            <div className="col-md-6">
              <img
                src="security-img.png"
                className="security-img"
                alt="Security Illustration"
              />
            </div>
            <div className="col-md-6">
              <div className="security-content">
                <h1 className="security-title">
                  Safely<span className="t-blue"> track your investments </span>from<span className="t-blue">
                    anywhere
                  </span> through your computer or mobile.
                </h1>
                <p className="security-para">
                  Enjoy a seamless investment experience with our all-in-one, smart platform, designed by
                  investment experts. It simplifies every step of your journey, from building your portfolio to
                  tracking its performance, adjusting portfolio weights, and reinvesting distributed dividends.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="invest pb-150" id="shariyah">
        <div className="container">
          <div className="row vcenter-item">
            <div className="col-md-6">
              <div className="invest-content">
                <h1 className="invest-title">
                  The appointment of<span className="t-blue"> Shariyah Review Bureau </span>
                  <img
                    src="shariyah-logo.png"
                    className="shariyah-logo"
                    alt="Shariyah Review Bureau Logo"
                  />
                  <br />
                  <span>by Tamra Capital as the Sharia advisor.</span>
                </h1>
                <p className="invest-para">
                  The Shariyah Review Bureau is responsible for overseeing and advising on Sharia compliance. The
                  Bureau has appointed a dedicated Sharia supervisory committee, which includes Sheikh Dr. Salah bin
                  Fahd Al-Shalhoub and Sheikh Muhammad Ahmed Sultan. Additionally, the Bureau will appoint a team of
                  Sharia auditors for periodic reviews and supervision of the company's operations, ensuring adherence
                  to Sharia standards and regulations.
                </p>
                <div className="shariyah-btn-wrapper">
                  <a target="_blank" href="https://bit.ly/3c5B5dF" className="shariyah-btn" rel="noopener noreferrer">
                    Accreditation Certificate
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src="shariyah.webp"
                className="invest-img"
                id="shariyah-img"
                alt="Shariyah Review Bureau Image"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Investment Calculator Section */}
      <section className="calculator pt-200 pb-100" id="investment-calculator">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="chart-config">
                <h3 className="calculator-title" id="investment-calculator--t" data-register-btn="Invest Today">
                  Investment Calculator
                </h3>
                <p>Plant your seeds today to reap its fruits tomorrow!</p>
                <h4 className="chart-title">Select a portfolio</h4>
                <div className="select">
                  <select
                    className="form-select form-select-lg select-wallet-type s-hidden"
                    aria-label=".form-select-lg example"
                  >
                    <option value="متحفظة"> المحفظة الهادئة</option>
                    <option value="متحفظة معتدلة">المحفظة المعتدلة</option>
                    <option value="مجازفة معتدلة">المحفظة المغامرة</option>
                    <option value="مجازفة"> المحفظة المجازفة</option>
                  </select>
                  <div className="styledSelect" data-lang="en">
                    Conservative Portfolio
                  </div>
                  <ul className="options display-none">
                    <li>Conservative Portfolio</li>
                    <li>Moderately Conservative Portfolio</li>
                    <li>Moderately Aggressive Portfolio</li>
                    <li>Aggressive Portfolio</li>
                  </ul>
                </div>
                <div className="clear-both"></div>
                <div className="rang-sliders" data-lang="en">
                  <div className="single-rang">
                    <label htmlFor="">Savings</label>
                    <label htmlFor="" className="numbers_ranged">
                      <span className="numbers_ranged_chng">
                        <input type="text" id="input_number" value="0" />
                      </span>
                      SAR
                    </label>
                    <div className="clear-both"></div>
                    <input
                      type="range"
                      className="form-range savings-range"
                      min="0"
                      max="1000000"
                      step="1000"
                      id="savings"
                      value="0"
                      style={{
                        background: 'linear-gradient(to right, rgb(245, 229, 154) 0%, rgb(245, 229, 154) 0%, rgb(63, 80, 145) 0%, rgb(63, 80, 145) 100%)',
                      }}
                    />
                    <div className="clear-both d-none-not-important"></div>
                    <label htmlFor="" className="blacked_ranged bld_r">
                      <span className="nbr-change">0</span> SAR
                    </label>
                  </div>
                  <div className="clear-both"></div>
                  <div className="single-rang">
                    <label htmlFor="">Monthly deposit</label>
                    <label htmlFor="" className="numbers_ranged">
                      <span className="numbers_ranged_chng">
                        <input type="number" id="input_number2" value="500" />
                      </span>
                      SAR
                    </label>
                    <div className="clear-both"></div>
                    <input
                      type="range"
                      className="form-range savings-range"
                      min="0"
                      max="50000"
                      step="100"
                      id="monthly-payments"
                      value="500"
                      style={{
                        background: 'linear-gradient(to right, rgb(245, 229, 154) 0%, rgb(245, 229, 154) 1%, rgb(63, 80, 145) 1%, rgb(63, 80, 145) 100%)',
                      }}
                    />
                    <div className="clear-both d-none-not-important"></div>
                    <label htmlFor="" className="blacked_ranged bld_r">
                      <span className="nbr-change">0</span> SAR
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <h4 className="chart-calc" data-lang="en">
                Calculate your annual potential returns based on the historically estimated rate of return (after
                deducting investment management fees).
                <i className="fa-solid fa-exclamation"></i>
              </h4>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="competitor nav-link active" data-value="competitor1">
                    Investment’s value with Competitor 1 (1% investment management fees)
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="competitor nav-link" data-value="competitor2">
                    Investment’s value with Competitor 2 (2% investment management fees)
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <img src="3.jpeg" alt="Investment Calculation Illustration" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="team pb-150" id="team">
        <div className="container">
          <div className="calcule-title">
            <h1 className="team-title" id="team--t">Our Team</h1>
          </div>
          <div className="row vcenter-item">
            <div className="col-md-6">
              <div className="team-active">
                <img
                  src="_omar.png"
                  className="team-active-img"
                  alt="Omar Hashem"
                />
              </div>
            </div>
            <div className="col-md-6" id="active-quote">
              <img
                style={{ width: '50px' }}
                src="btn-pause.png"
                className="team-slider-btn"
                alt="Play/Pause Button"
              />
              <h4 className="t-name opacity-100">Omar Hashem</h4>
              <p className="t-role opacity-100">Board Member</p>
              <p className="t-quote opacity-100">
                Has more than 30 years of experience in technology, payment management, finance, insurance, and
                strategies. He occupied many positions, most importantly: a member of the advisory board at
                souqalmal.com, and head of cash management at the National Commercial Bank. He also worked in the
                leading banks of the Kingdom of Saudi Arabia, and one of the top leading banks in The Middle East. Mr.
                Hashem was the pioneer of complex transformational projects, along with his banking and financial
                expertise. He also harnessed his spectacular digital skills to achieve growth in the investment field.
                Apart from this, he led one of the fastest merger and acquisition deals in the insurance sector in
                Saudi Arabia, between Al Ahli Takaful and Arabian Shield. Mr. Hashem holds a bachelor’s degree in
                Computer and Information Science from King Fahd University of Petroleum and Mineral, and completed a
                program of Advanced Management from The Wharton School of Business at the University of Pennsylvania in
                the US. Mr. Hashem believes that investing does not require deep financial expertise, as much as it is
                a decision and a step you take with the help of a financial expert in order to open up new horizons for
                yourself.
              </p>
              <div className="all-team">
                <div className="row" dir="rtl">
                  <div className="col-md-3">
                    <a href="https://tamracapital.sa/en#" className="team-select">
                      <img
                        src="_tarik.png"
                        className="team-img"
                        alt="Tarek Lenjawi"
                      />
                    </a>
                  </div>
                  <div className="col-md-3">
                    <a href="https://tamracapital.sa/en#" className="team-select">
                      <img
                        src="_salahdin.png"
                        className="team-img"
                        alt="Salahulddin Khashoggi"
                      />
                    </a>
                  </div>
                  <div className="col-md-3">
                    <a href="https://tamracapital.sa/en#" className="team-select">
                      <img
                        src="_tamir.png"
                        className="team-img"
                        alt="Thamer Al Saeed"
                      />
                    </a>
                  </div>
                  <div className="col-md-3">
                    <a href="https://tamracapital.sa/en#" className="team-select active-team">
                      <img
                        src="_omar.png"
                        className="team-img"
                        alt="Omar Hashem"
                      />
                    </a>
                  </div>
                  <div className="col-md-3">
                    <a href="https://tamracapital.sa/en#" className="team-select">
                      <img
                        src="_htan.png"
                        className="team-img"
                        alt="Hattan Rayes"
                      />
                    </a>
                  </div>
                  <div className="col-md-3">
                    <a href="https://tamracapital.sa/en#" className="team-select">
                      <img
                        src="_mohanad.png"
                        className="team-img"
                        alt="Mohannad Qandeel"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="pdf-home-btn-download-div">
                <a
                  href="https://tamracapital.sa/en/how-tamra-works#tamra-structure"
                  target="_blank"
                  className="main-btn btn-download pdf-home-btn-download"
                  rel="noopener noreferrer"
                >
                  Explore the Company Structure
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq pb-100" id="faq">
        <div className="container">
          <div className="main-title text-center">
            <h1 className="faq-main-title" id="faq--t" data-register-btn="Sign Up">
              Frequently Asked Questions
              <img src="icon-faq.png" className="icon-faq" alt="FAQ Icon" />
            </h1>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="accordion" id="faqAccordion1">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="faqheading1">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqcollapse1"
                      aria-expanded="true"
                      aria-controls="faqcollapse1"
                    >
                      What are the minimum amount to invest in the Tamra Platform?
                    </button>
                  </h2>
                  <div
                    id="faqcollapse1"
                    className="accordion-collapse collapse show"
                    aria-labelledby="faqheading1"
                    data-bs-parent="#faqAccordion1"
                  >
                    <div className="accordion-body">
                      The minimum investment amount for Tamra platform is 5,000 SAR.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="faqheading2">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqcollapse2"
                      aria-expanded="false"
                      aria-controls="faqcollapse2"
                    >
                      Is Tamra’s platform halal compliant?
                    </button>
                  </h2>
                  <div
                    id="faqcollapse2"
                    className="accordion-collapse collapse"
                    aria-labelledby="faqheading2"
                    data-bs-parent="#faqAccordion1"
                  >
                    <div className="accordion-body">
                      Yes, Tamra platform is compliant with Islamic laws.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="faqheading3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqcollapse3"
                      aria-expanded="false"
                      aria-controls="faqcollapse3"
                    >
                      How do I track my investment?
                    </button>
                  </h2>
                  <div
                    id="faqcollapse3"
                    className="accordion-collapse collapse"
                    aria-labelledby="faqheading3"
                    data-bs-parent="#faqAccordion1"
                  >
                    <div className="accordion-body">
                      You can track your investment status through your personalized dashboard, available 24/7 for you.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="accordion" id="faqAccordion2">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="faqheading4">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqcollapse4"
                      aria-expanded="true"
                      aria-controls="faqcollapse4"
                    >
                      How long does it take to withdraw funds?
                    </button>
                  </h2>
                  <div
                    id="faqcollapse4"
                    className="accordion-collapse collapse show"
                    aria-labelledby="faqheading4"
                    data-bs-parent="#faqAccordion2"
                  >
                    <div className="accordion-body">
                      Withdrawal requests are processed within 3 to 5 business days.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="faqheading5">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqcollapse5"
                      aria-expanded="false"
                      aria-controls="faqcollapse5"
                    >
                      Can I invest in multiple portfolios?
                    </button>
                  </h2>
                  <div
                    id="faqcollapse5"
                    className="accordion-collapse collapse"
                    aria-labelledby="faqheading5"
                    data-bs-parent="#faqAccordion2"
                  >
                    <div className="accordion-body">
                      Yes, you can invest in multiple portfolios based on your investment preferences.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="faqheading6">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faqcollapse6"
                      aria-expanded="false"
                      aria-controls="faqcollapse6"
                    >
                      Can I make changes to my portfolio after investing?
                    </button>
                  </h2>
                  <div
                    id="faqcollapse6"
                    className="accordion-collapse collapse"
                    aria-labelledby="faqheading6"
                    data-bs-parent="#faqAccordion2"
                  >
                    <div className="accordion-body">
                      Yes, you can make adjustments to your portfolio at any time by logging into your dashboard.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <img src="logo-w.svg" className="footer-logo" alt="Tamra Capital Logo" />
              <p className="footer-para">
                <span className="fs-11-px">
                  Tamra Capital is a closed joint stock company with a paid-up capital of 3,200,000 Saudi riyals and a
                  commercial registration No. 4030414862, subject to the supervision of the Capital Market Authority in
                  the Kingdom of Saudi Arabia with license No. 20212-30- to provide financial advisory services on
                  August 26, 2020, and to provide investment management services on January 14, 2021. Tamra Capital
                  received Capital Market Authority approval to begin operations on November 23, 2021.
                </span>
                <br />
                <span className="address-footer">
                  Address: 3992 Ibrahim Al-Anqari, 6667 Al-Muhammadiyah District, Jeddah 23617, Kingdom of Saudi Arabia
                </span>
              </p>
            </div>
            <div className="col-md-4">
              <div className="capital-market">
                <a href="https://shariyah.org/ccp/uploads/certificate_162ee396111d10.pdf" target="_blank" rel="noopener noreferrer">
                  <img src="Stamp.png" className="Stamp" alt="Shariyah Certificate" />
                </a>
                <a href="https://cma.org.sa/Market/NEWS/pages/CMA_N_2843.aspx" target="_blank" rel="noopener noreferrer">
                  <img
                    src="Capital-Market-Authority.png"
                    className="Capital"
                    alt="Capital Market Authority"
                  />
                </a>
              </div>
              <div className="apps-icons">
                <a href="https://apps.apple.com/sa/app/tamra-capital-save-invest/id1672321079" target="_blank" rel="noopener noreferrer">
                  <img src="appleStoreBtn.png" alt="Apple Store" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=sa.com.tamracapital&amp;hl=ar_SA" target="_blank" rel="noopener noreferrer">
                  <img src="googlePlayBtn.png" alt="Google Play" />
                </a>
              </div>
              <div className="payment-icons">
                <img src="visa.png" alt="Visa" />
                <img src="master_card.png" alt="MasterCard" />
                <img src="mada.png" alt="Mada" />
              </div>
              <div className="list-links">
                <a href="https://tamracapital.sa/privacy-policy" className="link-route">Privacy Policy</a>
              </div>
              <div className="list-links">
                <a
                  target="_blank"
                  href="https://tamracapital.sa/pdf/tamra-capital-terms-conditions.pdf?v=0.1"
                  className="link-route"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>
              </div>
              <div className="list-links">
                <a
                  target="_blank"
                  href="https://tamracapital.sa/pdf/tamra-capital-investment-management-fees.pdf?v=0.1"
                  className="link-route"
                  rel="noopener noreferrer"
                >
                  Fees Table
                </a>
              </div>
              <ul className="social-icons">
                <li><a href="https://www.instagram.com/tamracapital/" target="__blank" rel="noopener noreferrer"><img src="icon-instagram.svg" alt="Instagram" /></a></li>
                <li><a href="https://www.youtube.com/channel/UCGt_3jQ_-vFYQV68LpnX8ug/featured" target="__blank" rel="noopener noreferrer"><img src="icon-youtube.svg" alt="YouTube" /></a></li>
                <li><a href="https://twitter.com/tamracapital" target="__blank" rel="noopener noreferrer"><img src="icon-twitter.svg" alt="Twitter" /></a></li>
                <li><a href="https://www.linkedin.com/company/68506568/" target="__blank" rel="noopener noreferrer"><img src="icon-linkedin.svg" alt="LinkedIn" /></a></li>
              </ul>
              <div className="footer-email">
                <a href="mailto:info@tamracapital.sa" className="footer-email">info@tamracapital.sa</a>
              </div>
            </div>
          </div>
        </div>
      </footer>










      
    </div>
  );
}

export default App;
